import { useNotifyErrors } from '@/composables'
import { fetchTeamUsage } from '@api/distributor/team'
import { ref } from '@vue/composition-api'

const cached = []

export default function useTeamView() {
  const teamUsage = ref(null)

  const loadTeamUsage = id =>
    fetchTeamUsage(id)
      .then(({ data }) => {
        teamUsage.value = data.data
      })
      .catch(useNotifyErrors)

  return {
    teamUsage,
    loadTeamUsage,
  }
}
